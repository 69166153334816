<template>
    <div>
        <div class="banner-cls">
            <div class="main-paading-cls banner-box-cls">
                <div class="banner-main-box-cls">
                    <div class="logo-img-cls"></div>
                    <div class="title-box-cls">
                        <div>网络安全应急服务支撑单位管理系统</div>
                        <div>Management System Of CNCERT Network Security Emergency Response Service Support Unit</div>
                    </div>
                    <div style="cursor: pointer;" class="account-exists-box-cls" @click="goLogin"><span>已有账号？</span><span>请登录></span></div>
                </div>
            </div>
        </div>
        <div class="middle-main-cls ">
            <div style="height: 40px;" ></div>
            <div class="mid-box-main-cls">
                <el-form class="form-cls" ref="ruleForm" :label-position="labelPosition" :model="form" :rules="rules">
                    <el-form-item label="统一社会信用代码" prop="unifiedSocialCreditCode">
                        <el-input v-model="form.unifiedSocialCreditCode" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="单位全称（中文）" prop="companyChineseName">
                        <el-input v-model="form.companyChineseName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="mobile">
                        <el-input v-model="form.mobile" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码" prop="validCode" class="change-message">
                        <el-input v-model="form.validCode" autocomplete="off"></el-input>
                        <a @click="getYzm" v-if="djs">发送验证码</a>
                        <a v-else style="color: #ccc"><span style="color: #ccc">{{count}}</span>s</a>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input v-model="form.password" type="password" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email">
                        <el-input v-model="form.email" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label=" " style="text-align: right; padding-bottom: 0px !important;">
                        <el-button :disabled="!checkRegAgreement" type="primary" @click="doRegist" style="width:100%;">注册</el-button>
                    </el-form-item>
                    <el-form-item label=" " style="text-align: left;padding-top: 0px !important;">
                       <el-checkbox v-model="checkRegAgreement" style="font-size: 13px !important;" label="已阅读并同意以下"></el-checkbox>
                        <span class="span1-cls">&nbsp;&nbsp;服务协议、隐私权政策、法律声明</span>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="register_bottom">
            <p style="margin: 20px 0 15px">国家局提示您：请慎重申报或慎重查看相关公司的申报信息</p>
            <p><span>© 国家计算机网络应急技术处理协调中心版权所有</span><span style="margin: 0 50px;">Email：cncert@cert.org.cn</span><span>京ICP备10012421号-2</span></p>
            <p style="margin-top: 10px"><span>网站由北京安天网络安全技术有限公司提供技术支持</span></p>
            <p style="margin-top: 15px"> 使用条款 隐私政策</p>
        </div>
    </div>
</template>
<script>
import "../../assets/css/media-screen.scss";

export default {
    data() {
        const validatorPhone = function(rule, value, callback) {
            if (!/^1\d{10}$/.test(value)) {
                callback(new Error('手机号格式错误'))
            } else {
                callback()
            }
        }
        const checkEmail = (rule, value, callback) => {
            const mailReg = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            if (mailReg.test(value)) {
                callback()
            } else {
                callback(new Error('请输入正确的邮箱格式'))
            }
        }
        return {
            checkRegAgreement: false,
            djs: true,
            count: 60,
            timer: null,
            labelPosition: "right",
            form: {
                /**
                 * 统一社会信用代码
                 */
                unifiedSocialCreditCode: '',

                /**
                 * 单位全称(中文)
                 */
                companyChineseName: '',

                /**
                 * 手机号
                 */
                mobile: '',

                /**
                 * 密码
                 */
                password: '',

                /**
                 * 邮箱
                 */
                email: '',

                /**
                 * 【DTO属性】验证码
                 */
                validCode: ''
            },
            rules: {
                unifiedSocialCreditCode: { required: true, message: '统一社会信用代码不能为空', trigger: 'blur' },
                companyChineseName: { required: true, message: '单位全称（中文）不能为空', trigger: 'blur' },
                mobile: [{ required: true, message: '手机号不能为空', trigger: 'blur' },
                    { validator: validatorPhone, trigger: 'blur' }
                ],
                validCode: { required: true, message: '验证码不能为空', trigger: 'blur' },
                password: { required: true, message: '密码不能为空', trigger: 'blur' },
                email: [{ required: true, message: '邮箱不能为空', trigger: 'blur' },
                    { validator: checkEmail, trigger: 'blur' }
                ]
            },
        }
    },
    methods: {
        goLogin(){
              this.$router.push({
                  name: "login",
            })
        },
        getYzm() {
            if (this.form.mobile.length == 11) {
                this.count = 60;
                this.djs = false;
                this.timer = setInterval(() => {
                    if (this.count == 1) {
                        clearInterval(this.timer);
                        this.djs = true;
                    }
                    this.count--;

                }, 1000)
                let params = {
                    mobile: this.form.mobile,
                    unifiedSocialCreditCode: this.form.unifiedSocialCreditCode
                }
                this.qa.getRegValidCode(params).then(res => {

                })
            } else if (this.form.unifiedSocialCreditCode.length == 0) {
                this.$message({
                    message: '请先填写统一社会信用代码！',
                    type: 'warning'
                });
            } else if (this.form.mobile.length == 0) {
                this.$message({
                    message: '请先填写手机号！',
                    type: 'warning'
                });
            } else {
                this.$message({
                    message: '手机号格式不正确！',
                    type: 'warning'
                });
            }

        },
        //注册
        doRegist() {
            
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.qa.doAddSupportCompanyApply(this.form).then(res => {
                        this.$message({
                            message: '注册成功，即将返回登录页！',
                            type: 'success'
                        });
                        setTimeout(() => {
                            this.$router.push({
                                name: 'login'
                            })
                        }, 1500)
                    })
                } else {
                    return false;
                }
            })

        }
    }
}
</script>
<style scoped>



div.banner-cls {
    position: relative;
        height: 1.21rem;
        background-color: #fff;
        box-shadow: 0px 0.05rem 0.07rem 0.03rem rgba(0, 0, 0, 0.05);
    }


div.banner-box-cls {
    position: relative;
}

div.main-paading-cls {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 12rem;
    height: 100%;
}

div.banner-box-cls>div.banner-main-box-cls {
    height: 0.6rem;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}





div.logo-img-cls {
    width: 1.7rem;
    height: 0.3rem;
    background-image: url(../../assets/images/common/logo2.png);
    background-repeat: no-repeat;
    background-position: 0 4px;
    background-size: 100% 100%;
}

div.banner-main-box-cls>div {
    float: left;
}

div.title-box-cls {
    font-size: 0.2rem;
    width: 8rem;
    text-align: left;
    padding-left: 0.1rem;
}

div.title-box-cls>div:nth-child(1) {
    font-size: 0.24rem;
    color: #424242;
    font-family: "Source Han Sans CN", "PingFang-SC-Medium", "Microsoft YaHei";
    font-weight: bold;
}

div.title-box-cls>div:nth-child(2) {
    font-size: 0.12rem;
    color: #424242;
}

div.account-exists-box-cls {
    font-size: 0.12rem;
    width: 1.5rem;
    margin-top: 0.2rem;
    float: right;
}

div.account-exists-box-cls>span:nth-child(1) {
    color: #999999;
}

div.account-exists-box-cls>span:nth-child(2) {
    color: #ff2f2f;
}

div.middle-main-cls{
    height: 100%;
    text-align: center;
    position: relative;
}

div.middle-main-cls>div.mid-box-main-cls {
   
   text-align: center;
   margin:auto;
    height: 100%;
    width: 6rem;
}

div.mid-box-main-cls>>>form>div {
    padding-top: 0.14rem;
    padding-bottom: 0.14rem;
}

div.mid-box-main-cls>>>form>div>label {
    width: 1.55rem;

    font-size: 0.12rem;
}

div.mid-box-main-cls>>>form>div>div {

    font-size: 0.12rem;
}

.change-message {
    clear: both;
    position: relative;
}

.change-message a {
    width: 100px;
    bottom: 10px;
    right: 0;
    position: absolute;
    border: 0;
    font-size: 14px;
    color: #2f84ff;
    line-height: 21px;
}

.register_bottom {
    width: 100%;
    height: 142px;
    color: #424242;
    border-top: 1px solid #DCDFE6;
    position: relative;
    font-size: 0.12rem;
}

div.read-text-cls {
    font-size: 0.12rem;
    text-align: right;
    margin-right: 0.12rem;
}

span.span1-cls {
    color: #409eff;
    font-size: 13px;
}
div.middle-main-cls>>>span.el-checkbox__label{
    font-size: 13px;
    font-weight: normal;
}
div.read-text-cls>>>label.el-checkbox {}
</style>